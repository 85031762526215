<template>
  <div>
    <!-- 导航 -->
    <van-nav-bar title="退货详情" left-arrow @click-left="onClickLeft" />
    <div class="container">
      <div class="handle">
        <!-- 信息2 -->
        <div class="title2" v-if="this.refundBottomInfo.status == 22">
          <div>商家拒绝</div>
          <div style="margin: 10px 0">原因：货物破损</div>
          <div>你可以同商家协调，修改申请后再次发起，商家会重新处理</div>
        </div>
        <div class="title" v-if="this.refundBottomInfo.status == 2">
          <span style="font-size: 16px; margin-right: 10px">等待商家处理</span>
          <span style="color: #999999">还剩24时00分00秒</span>
        </div>
        <div class="title" v-if="this.refundBottomInfo.status == 21">
          <span style="font-size: 16px; margin-right: 10px">待退货</span>
          <span style="color: #999999">还剩24时00分00秒</span>
        </div>
        <div class="steps">
          <van-steps direction="vertical" :active="stepNum">
            <van-step>
              <h3>提交申请</h3>
            </van-step>
            <van-step>
              <h3>商家审核</h3>
            </van-step>
            <van-step>
              <h3>返回商品</h3>
            </van-step>
            <van-step>
              <h3>售后完成</h3>
            </van-step>
          </van-steps>
          <div class="desc">
            <span v-if="this.refundBottomInfo.status == 2"
              >你已经成功发起退款审核，请耐心等待商家处理，
              商家同意或者超时未处理，系统将退款给你。如果商家拒绝
              你可以修改申请再次发起，商家重新会处理
            </span>
            <span v-if="this.refundBottomInfo.status == 21"
              >商家同意退货，请将商品在规定时间内退回商家指导地址</span
            >
          </div>
        </div>
      </div>
      <!-- 地址 -->
      <div class="address" v-if="!this.$route.query.logisticsNo">
        <div class="_address">
          <span>退回地址</span>
          <div class="shop-info">
            <div class="_shop-info">
              <div class="image">
                <img
                  style="width: 100%; height: 100%"
                  :src="this.refundBottomInfo.userHeadUrl"
                  alt
                />
              </div>
              <span>{{ this.refundBottomInfo.shopName }}</span>
              <span id="text">{{ this.refundBottomInfo.telNum }}</span>
              <label style="display: block">
                <textarea
                  id="textArea"
                  style="opacity: 0; width: 10px"
                ></textarea>
              </label>
            </div>
            <span class="copy1" :data-clipboard-text="textCopy"  @click="onCopy">复制</span>
          </div>
          <div class="place">{{ this.refundBottomInfo.address }}</div>
          <div class="a-btn">
            <span></span>
            <van-button
              style="margin-right: 10px"
              type="default"
              round
              size="small"
              @click="toWriteLog"
              >填写物流信息</van-button
            >
          </div>
        </div>
      </div>
      <!-- 地址2 -->
      <div class="address2" v-else>
        <div class="_address2" v-if="this.logisticsData">
          <van-steps direction="vertical" :active="0">
            <van-step  v-for="(item, index) in logisticsData" :key="index">
              <h3>{{item.context}}</h3>
            </van-step>
          </van-steps>
        </div>
        <div v-else>
          <span style="margin-left: 20px">暂无物流信息</span>
        </div>
      </div>
      <div class="item">
        <div class="_item">
          <div class="item-info">
            <img
              style="width: 80px; height: 80px"
              :src="this.refundBottomInfo.orderItem.picUrl"
              alt
            />
            <div class="desc-spec">
              <div class="desc">
                <span style="display: inline-block; width: 180px">{{
                  this.refundBottomInfo.orderItem.spuName
                }}</span>
                <span>￥ {{ this.refundBottomInfo.orderItem.paymentPrice }}</span>
              </div>
              <div class="spec">
                <span>规格：{{ this.refundBottomInfo.orderItem.specInfo }}</span>
                <span>*1</span>
              </div>
            </div>
          </div>
        </div>
        <div class="return-money" style="padding-top: 15px">
          <span>退款金额</span>
          <span style="color: #fb2b53"
            >￥{{ this.refundBottomInfo.refundAmount }}</span
          >
        </div>
        <div class="return-money">
          <span>退款类型</span>
          <span>{{
            this.refundBottomInfo.orderType == 0 ? "普通订单 " : "酒店住宿订单"
          }}</span>
        </div>
        <div class="return-money">
          <span>退款原因</span>
          <span>{{ this.refundBottomInfo.refundReson }}</span>
        </div>
        <div class="return-money">
          <span>申请时间</span>
          <span>{{ this.refundBottomInfo.createTime }}</span>
        </div>
        <div class="return-money">
          <span style="flex: 1">订单编号</span>
          <span id="text">{{ this.refundBottomInfo.orderItemId }}</span>
          <label style="display: block">
            <textarea id="textArea" style="opacity: 0; width: 10px"></textarea>
          </label>
          <span class="copy2" @click="onCopy2" :data-clipboard-text="textCopy2">复制</span>
        </div>
        <div class="last-btn">
          <div style="flex: 1"></div>
          <van-button
            v-if="
              this.refundBottomInfo.status == 2 ||
              this.refundBottomInfo.status == 21
            "
            style="margin-right: 10px"
            type="default"
            round
            size="small"
            @click="returnApply"
            >撤销申请</van-button
          >
          <van-button
            v-if="this.refundBottomInfo.status == 2"
            type="default"
            color="#fb2b53"
            round
            size="small"
            @click="changeApply"
            >修改申请</van-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { Toast } from "vant";
import {
  getGoodsDetail,
  returnAllApply,
  getLogisticsData,
} from "@/api/afterSales";
export default {
  data() {
    return {
      refundBottomInfo: {},
      stepNum: 0,
      refundInfo: null, //商品信息
      logisticsData: [],
      logistics: '',
      logisticsNo: '',
      textCopy: '',
      textCopy2: '1111111111'
    };
  },
  created() {
    this.logistics = localStorage.getItem('logistics')
    this.logisticsNo = localStorage.getItem('logisticsNo')
    this.getLogisticsData();
    this.$store.commit("setSession", this.$route.query.session);
    this.getGoodsDetail();
    this.refundInfo = JSON.parse(localStorage.getItem("refundInfo"));
        console.log('==========');
        console.log(this.refundInfo);
    if (this.refundBottomInfo.status == 2) {
      this.stepNum = 0;
    } else if (this.refundBottomInfo.status == 21) {
      this.stepNum = 1;
    } else if (this.refundBottomInfo.status == 211) {
      this.stepNum = 3;
    }
    
  },
  activated() {
    this.getGoodsDetail();
    // 查物流信息
    this.getLogisticsData();
  },
  mounted() {
   
  },
  methods: {
    // 获取物流信息
    getLogisticsData() {
      let params = {
        logistics: this.logistics,
        logisticsNo: this.logisticsNo,
      };
      console.log(params, "params");
      getLogisticsData(params).then((res) => {
        if (res.data.code === 0) {
          this.logisticsData = res.data.data.data;
          console.log(this.logisticsData, "物流");
        }
      });
    },
    // 售后商品
    getGoodsDetail() {
      let id = this.$route.query.id || "1474646085450543106";
      getGoodsDetail(id).then((res) => {
        if (res.data.code === 0) {
          this.refundBottomInfo = res.data.data;
          this.textCopy =  this.refundBottomInfo.telNum
          this.textCopy2 =  this.refundBottomInfo.orderItemId
        }
      });
    },
    // 撤销申请
    returnApply() {
      let params = {
        id: this.$route.query.id || "1474646085450543106",
        delFlag: "1",
        status: "0",
      };
      returnAllApply(params).then((res) => {
        console.log(111);
        if (res.data.code === 0) {
          //  console.log(res);
          this.$Toast("撤销申请成功");
        }
      });
    },
    // 修改申请
    changeApply() {},
    onClickLeft() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        window.android.close();
      }
    },
    // 去填写路由
    toWriteLog() {
      this.$router.push("/writeLog");
      localStorage.setItem(
        "addressData",
        JSON.stringify(this.refundBottomInfo)
      );
    },
    onCopy() {
     var clipboard = new this.Clipboard(".copy1");
      clipboard.on("success", () => {
        this.$toast("复制成功");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        this.$toast("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
    onCopy2() {
      var clipboard = new this.Clipboard(".copy2");
      clipboard.on("success", () => {
        this.$toast("复制成功");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        this.$toast("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-nav-bar {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
}
.container {
  position: relative;
  margin-top: 46px;
  width: 100%;
  height: calc(100vh - 56px);
  background-color: #f1f1f1;
  box-sizing: border-box;
  padding: 10px 0;
  .handle {
    background-color: #fff;
    box-sizing: border-box;
    padding: 15px;
    .title {
      margin-left: 20px;
    }
    .title2 {
      padding-left: 15px;
    }
    .steps {
      .desc {
        padding: 5px 20px;
        color: #333333;
        font-family: PingFang-SC-Regular;
      }
    }
  }
  .address {
    width: 100%;
    height: 176px;
    background-color: #fff;
    margin-top: 10px;
    margin-bottom: 10px;
    ._address {
      box-sizing: border-box;
      padding: 20px;
      .shop-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
        ._shop-info {
          display: flex;
          align-items: center;
          .image {
            width: 30px;
            height: 30px;
            // border: 1px solid #333;
            border-radius: 50%;
          }
          span {
            margin-left: 10px;
          }
        }
        .copy1 {
          color: #fb2b53;
          display: inline-block;
          border-bottom: 1px solid #fb2b53;
          height: 18px;
        }
      }
      .place {
        color: #999999;
        margin-left: 38px;
      }
      .a-btn {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        /deep/ .van-button__text {
          margin: 0 10px;
        }
      }
    }
  }
  .address2 {
    padding: 10px;
    background-color: #fff;
    margin-top: 18px;
    margin-bottom: 15px;
    ._address2 {
      .van-steps {
        .van-step {
          p {
            margin-top: 6px;
          }
        }
      }
    }
  }
  .item {
    background-color: #fff;
    width: 100%;
    // height: 300px;
    box-sizing: border-box;
    padding: 20px 20px 0;
    margin: 10px 0;
    // margin-top: 15px;
    ._item {
      .title {
        font-size: 17px;
      }
      .item-info {
        margin-top: 10px;
        display: flex;
        .desc-spec {
          margin-left: 10px;
          flex: 1;
          .desc {
            display: flex;
            justify-content: space-between;
          }
          .spec {
            margin-top: 10px;
            color: #999999;
            font-family: PingFang-SC-Regular;
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
    .return-money {
      display: flex;
      height: 45px;
      align-items: center;
      // background-color: pink;
      justify-content: space-between;
      color: #333333;
      .copy2 {
        color: #fb2b53;
        margin-left: 10px;
        display: inline-block;
        border-bottom: 1px solid #fb2b53;
        height: 18px;
        margin-top: -2px;
      }
    }
    .last-btn {
      padding-bottom: 15px;
      display: flex;
      justify-content: space-between;
      /deep/ .van-button__text {
        margin: 0 10px;
      }
    }
  }
}

/deep/ .van-step--vertical {
  padding: 20px 20px 20px 0;
}
/deep/ .van-step--vertical:not(:last-child)::after {
  border: none;
}
/deep/ .van-step__title {
  margin-top: -11px;
}
/deep/ .van-step__title--active {
  color: #3e85ff;
}
/deep/ .van-step__icon--active {
  color: #3e85ff;
}
/deep/ .van-step--finish .van-step__circle,
.van-step--finish .van-step__line {
  background-color: #3e85ff;
}
/deep/.van-step--finish .van-step__line {
  background-color: #3e85ff;
}
</style>